import moment from "moment";

function getMomentDate(value) {
  moment.locale("pt-br");
  var dateMoment = moment(value, "DD/MM/YYYY HH:mm", true);
  return dateMoment.isValid() ? dateMoment : moment(value);
}

export default function(value) {
  if (!value) {
    return value;
  }

  let dateMoment = getMomentDate(value);
  let result = dateMoment.isValid() ? dateMoment.format("ddd DD MMM") : value;
  result = result
    .split(" ")
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(" ");

  return result;
}
